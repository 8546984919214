import {
  apiAgentPropertiesAddress,
  apiPropertyItem,
  apiPublicPropertiesList,
} from '@src/interfaces/api/publicProperties';
import { apiAgentPropertiesList } from '@src/interfaces/api/agentProperties';
import {
  Property,
  ImageInformation,
  Address,
  AmenityInformation,
  PlaceInformation,
  PropertyDetails,
  chargeInformation,
} from '@src/interfaces/app/propertyLists';
import { PropertyFilter } from '@src/interfaces/utils/http';
import { format, formatRelative, isPast } from 'date-fns';
import { de } from 'date-fns/esm/locale';
import {
  Amenity,
  FinixAddress,
  RentalHistoryFormObject,
  RenterProfile,
} from '@src/interfaces/profile';
import { RenterApiProfile } from '@src/interfaces/api/userProfile';
import {
  DocumentsInformation,
  AddPropertyPayload,
  CreatePropertyPayload,
  ImageInformation as ImageInfoInterface,
} from '@src/interfaces/app/agent/addProperty';
import { PropertyPreviewDetails } from '@src/interfaces/app/addProperty';
import { EditUtility, FEE_TYPES } from '@src/constants/addProperty';
import { DATE_FORMATS, TIME_FORMATS } from '@src/constants/dateFormat';
import { ApplicationState } from '@src/interfaces/components/application';
import {
  APPLICATION_CO_APPLICANT_ENUM,
  APPLICATION_STATES_ENUM,
  APPLICATION_STATES_LABELS_ENUM,
} from '@src/constants/application';
import { ZEME_USERS_ROLE } from '@src/constants/configs';
import {
  AllApplication,
  Applicant,
  GET_SORT_APPLICATION_LABLE_ENUM,
  SORT_OPTIONS,
} from '@src/interfaces/app/renter/my-application';
import { getUserId, getUserType } from './localStorage';
import { InitialEditRenterValues } from '@src/interfaces/app/settings/edit-profile/renterEditProfile';
import { RegisterRenterPayload } from '@src/interfaces/components/wizard/register/details';
import {
  AgentRegisterEditValues,
  AgentRegisterPayload,
  InitialAgentRegisterValues,
} from '@src/interfaces/app/auth/register';
import { capitalize, cloneDeep, lowerCase, round } from 'lodash';
import { AddressAutoSearch } from '@src/interfaces/components/searchAutocomplete';
import { DEFAULT_HEADER_HEIGHT, NAME_PATTERN } from '@src/constants/common';

/**
 *
 * @param {number} amount
 * @param {boolean} convertToShortRepresentation
 * @returns {String}
 */
const formatCurrency = (
  amount: number,
  convertToShortRepresentation = false
) => {
  const formatterOptions: Intl.NumberFormatOptions = {
    style: convertToShortRepresentation ? 'decimal' : 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  if (convertToShortRepresentation) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];

    if (amount < 1000) return `$${amount}`; // Include '$' for amounts less than 1,000

    const logValue = Math.log10(amount);
    const suffixIndex = Math.floor(logValue / 3);
    const shortValue = amount / Math.pow(1000, suffixIndex);

    // Check if the shortValue is an integer (no decimal part) or not
    if (shortValue % 1 === 0) {
      return `$${shortValue.toFixed(0)}${suffixes[suffixIndex]}`;
    } else {
      const formattedValue = shortValue.toFixed(1).replace(/(\.0+|0+)$/, ''); // Remove .0 and trailing zeros if present
      return `$${formattedValue}${suffixes[suffixIndex]}`;
    }
  }

  return new Intl.NumberFormat('en-US', formatterOptions).format(amount);
};

/**
 * Formats a currency amount to two decimal places with rounding.
 *
 * @param {number} amount - The amount to be formatted.
 * @return {string} The formatted currency amount.
 */
const formatCurrencyTwoDigitWithRound = (amount: number) => {
  return `$${(Math.round(amount * 100) / 100).toFixed(2)}`;
};

/**
 * This method is used to get formatted data for map. Map component will accept only specific format.
 * Format mentioned in return state of method.
 * @param {Array} list
 * @returns array
 */
const formatMapComponentData = (list: Property[]) => {
  let mapList = [];

  mapList = list.map((data: Property) => {
    const {
      generalInformation: {
        address: {
          coordinates: { lat = 0, lng = 0 } = {},
          primary = '',
          state = '',
          country = '',
          postalCode = '',
        } = {},
        bathrooms = 0,
        bedrooms = 0,
        squareFootage = 0,
      } = {},
      economicInformation: { cost = 0 } = {},
      imageInformation: { images },
    } = data || {};
    // Store first image to map box component
    const image = images.length > 0 ? images[0].image : '';

    return {
      lat,
      lng,
      isSaved:
        (getUserType() === ZEME_USERS_ROLE.AGENT ||
          getUserType() === ZEME_USERS_ROLE.LANDLORD) &&
        data?.generalInformation?.agent?.id === getUserId(),
      popup: {
        address: `${primary || ''}, ${state || ''}, ${country || ''}, ${
          postalCode || ''
        }`,
        bedrooms,
        bathrooms,
        squareFootage,
        cost,
        image,
      },
    };
  });

  return mapList;
};

/**
 * This method is used to convert API response to FE response which we used in other component.
 * The reason behind creating this helper function is If api missing key then FE will not break.
 *
 * @param {Array} list
 * @returns {Array} Custom response
 */
const transformAllPublicPropertiesData = (list: apiPublicPropertiesList[]) => {
  let propertyList = [];

  propertyList = list.map((item: apiPublicPropertiesList) => {
    return {
      id: item.id || '',
      generalInformation: {
        agent: {
          id: item.general_information?.agent?.id || '',
          generalInformation: {
            firstName:
              item.general_information?.agent?.general_information
                ?.first_name || '',
            middleName:
              item.general_information?.agent?.general_information
                ?.middle_name || '',
            lastName:
              item.general_information?.agent?.general_information?.last_name ||
              '',
            verified:
              item.general_information?.agent?.general_information?.verified ||
              false,
          },
          agentInformation: {
            profilePicture:
              item.general_information?.agent?.agent_information
                ?.profile_picture || '',
            companyName:
              item.general_information?.agent?.agent_information
                ?.company_name || '',
            bio: item.general_information?.agent?.agent_information?.bio || '',
            profileUrlIdentifier:
              item.general_information?.agent?.agent_information
                ?.profile_url_identifier || '',
          },
        },

        address: {
          coordinates: {
            lat: item.general_information?.address?.coordinate?.latitude || 0,
            lng: item.general_information?.address?.coordinate?.longitude || 0,
          },
          primary: item.general_information?.address?.primary || '',
          neighborhood: item.general_information?.address?.neighborhood || '',
          state: item.general_information?.address?.state || '',
          country: item.general_information?.address?.country || '',
          postalCode: item.general_information?.address?.postal_code || '',
          unitCode: item.general_information?.address?.unit_code || null,
          route: item.general_information?.address?.route || '',
        },
        bedrooms: item.general_information?.bedrooms || 0,
        cost: item.economic_information?.cost || 0,
        bathrooms: item.general_information?.bathrooms || 0,
        squareFootage: item.general_information?.square_footage || 0,
        propertyName: item.general_information?.property_name || '',
        dateAvailable: item.general_information?.date_available || '',
        description: item.general_information?.description || '',
        timePosted: item.general_information?.time_posted || '',
        isEasyApply: item.general_information?.is_easy_apply || false,
        isExclusive: item.general_information?.is_exclusive || false,
        nextPaymentDate: item?.general_information?.next_payment_date || '',
      },
      imageInformation: {
        images:
          item.image_information?.images?.map((image) => ({
            id: image.id || 0,
            image: image.image || '',
            thumbnail: image.thumbnail || image.image || '',
          })) || [],
      } as ImageInformation,
      economicInformation: {
        cost: item.economic_information?.cost || 0,
        updatedCost: item.economic_information?.updated_cost || 0,
        minCredit: item.economic_information?.min_credit || 0,
        minSalary: item.economic_information?.min_salary || 0,
        netEffectiveRent: item.economic_information?.net_effective_rent || 0,
        leaseConcession: item.economic_information?.lease_concession || false,
        leaseMonthsFree: item.economic_information?.lease_months_free || 0,
        leaseTerm: item.economic_information?.lease_term || '',
      },
      chargeInformation: {
        brokerFee: item.charge_information?.broker_fee || 0,
      },
      isSave: false,
      amountToBeCharged: item?.amount_to_be_charged || 0,
      creationDate: item?.creation_date || '',
      lastUpdate: item?.last_update || '',
    };
  });

  return propertyList;
};

const transformAllAgentPropertiesData = (list: apiAgentPropertiesList[]) => {
  let propertyList = [];

  propertyList = list.map((item: apiAgentPropertiesList) => {
    return {
      id: item.id || '',
      generalInformation: {
        agent: {
          id: item.general_information?.agent?.id || '',
          generalInformation: {
            firstName:
              item.general_information?.agent?.general_information
                ?.first_name || '',
            middleName:
              item.general_information?.agent?.general_information
                ?.middle_name || '',
            lastName:
              item.general_information?.agent?.general_information?.last_name ||
              '',
            verified:
              item.general_information?.agent?.general_information?.verified ||
              false,
          },
          agentInformation: {
            profilePicture:
              item.general_information?.agent?.agent_information
                ?.profile_picture || '',
            companyName:
              item.general_information?.agent?.agent_information
                ?.company_name || '',
            bio: item.general_information?.agent?.agent_information?.bio || '',
            profileUrlIdentifier:
              item?.general_information?.agent?.agent_information
                ?.profile_url_identifier || '',
          },
        },

        address: {
          coordinates: {
            lat: item.general_information?.address?.coordinate?.latitude || 0,
            lng: item.general_information?.address?.coordinate?.longitude || 0,
          },
          primary: item.general_information?.address?.primary || '',
          neighborhood: item.general_information?.address?.neighborhood || '',
          state: item.general_information?.address?.state || '',
          country: item.general_information?.address?.country || '',
          postalCode: item.general_information?.address?.postal_code || '',
          unitCode: item.general_information?.address?.unit_code || null,
        },
        bedrooms: item.general_information?.bedrooms || 0,
        cost: item.economic_information?.cost || 0,
        bathrooms: item.general_information?.bathrooms || 0,
        squareFootage: item.general_information?.square_footage || 0,
        propertyName: item.general_information?.property_name || '',
        dateAvailable: item.general_information?.date_available || '',
        description: item.general_information?.description || '',
        timePosted: item.general_information?.time_posted || '',
        isOpen: item.general_information?.is_open || false,
        sevenDayExpiryDate:
          item.general_information?.seven_day_expiry_date || '',
        sevenDayNonPaymentCycleStartDate:
          item.general_information?.seven_day_non_payment_cycle_start_date ||
          '',
        nextPaymentDate: item.general_information?.next_payment_date || '',
        isFreeThroughSubscription:
          item.general_information?.is_free_through_subscription || false,
        isExclusive: item.general_information?.is_exclusive || false,
      },
      imageInformation: {
        images:
          item.image_information?.images?.map((image) => ({
            id: image.id || 0,
            image: image.thumbnail || '',
            thumbnail: image.thumbnail || image.image || '',
          })) || [],
      } as ImageInformation,
      economicInformation: {
        cost: item.economic_information?.cost || 0,
        updatedCost: item.economic_information?.updated_cost || 0,
        minCredit: item.economic_information?.min_credit || 0,
        minSalary: item.economic_information?.min_salary || 0,
        netEffectiveRent: item.economic_information?.net_effective_rent || 0,
        leaseConcession: item.economic_information?.lease_concession || false,
        leaseMonthsFree: item.economic_information?.lease_months_free || '',
        leaseTerm: item.economic_information?.lease_term || '',
      },
      chargeInformation: {
        brokerFee: item.charge_information?.broker_fee || 0,
      },
      statisticInformation: {
        views: item.statistic_information?.views || 0,
        saves: item.statistic_information?.saves || 0,
        applications: item.statistic_information?.applications || 0,
        submittedApplications:
          item.statistic_information?.submitted_applications || 0,
      },
    };
  });

  return propertyList;
};

/**
 * This method is used to convert API response to FE response which we used in other component.
 * The reason behind creating this helper function is If api missing key then FE will not break.
 *
 * @param {Array} list
 * @returns {Array} Custom response
 */
const transformPropertyItem = (item: apiPropertyItem | undefined | null) => {
  return {
    id: item?.id,
    generalInformation: {
      agent: {
        id: item?.general_information?.agent?.id || 0,
        generalInformation: {
          firstName:
            item?.general_information?.agent?.general_information?.first_name ||
            '',
          middleName:
            item?.general_information?.agent?.general_information
              ?.middle_name || '',
          lastName:
            item?.general_information?.agent?.general_information?.last_name ||
            '',
          verified:
            item?.general_information?.agent?.general_information?.verified ||
            false,
        },
        agentInformation: {
          profilePicture:
            item?.general_information?.agent?.agent_information
              ?.profile_picture || '',
          companyName:
            item?.general_information?.agent?.agent_information?.company_name ||
            '',
          bio: item?.general_information?.agent?.agent_information?.bio || '',
          profileUrlIdentifier:
            item?.general_information?.agent?.agent_information
              ?.profile_url_identifier || '',
        },
      },
      address: {
        coordinates: {
          lat: item?.general_information?.address?.coordinate?.latitude || 0,
          lng: item?.general_information?.address?.coordinate?.longitude || 0,
        },
        primary: item?.general_information?.address?.primary || '',
        neighborhood: item?.general_information?.address?.neighborhood || '',
        state: item?.general_information?.address?.state || '',
        country: item?.general_information?.address?.country || '',
        postalCode: item?.general_information?.address?.postal_code || '',
        unitCode: item?.general_information?.address?.unit_code || null,
        route: item?.general_information?.address?.route || '',
      } as Address,
      bedrooms: item?.general_information?.bedrooms || 0,
      cost: item?.economic_information?.cost || 0,
      bathrooms: item?.general_information?.bathrooms || 0,
      squareFootage: item?.general_information?.square_footage || 0,
      propertyName: item?.general_information?.property_name || '',
      dateAvailable: item?.general_information?.date_available || '',
      description: item?.general_information?.description || '',
      timePosted: item?.general_information?.time_posted || '',
      isEasyApply: item?.general_information?.is_easy_apply || false,
      isOpen: item?.general_information?.is_open || false,
      isExclusive: item?.general_information?.is_exclusive,
      isApplicationRequestEnabled:
        item?.general_information?.is_application_request_enabled,
    },
    economicInformation: {
      cost: item?.economic_information?.cost || 0,
      updatedCost: item?.economic_information?.updated_cost || 0,
      minCredit: item?.economic_information?.min_credit || 0,
      minSalary: item?.economic_information?.min_salary || 0,
      netEffectiveRent: item?.economic_information?.net_effective_rent || 0,
      leaseMonthsFree: item?.economic_information?.lease_months_free || '',
      leaseTerm: item?.economic_information?.lease_term || '',
      leaseConcession: item?.economic_information?.lease_concession || false,
    },
    imageInformation: {
      images:
        item?.image_information?.images?.map((image) => ({
          id: image.id || 0,
          image: image.image || '',
          thumbnail: image.thumbnail || '',
        })) || [],
    } as ImageInformation,
    amenityInformation: {
      amenities:
        item?.amenity_information?.amenities?.map((amenity) => ({
          type: amenity.type || '',
          included: amenity.included || false,
        })) || [],
    } as AmenityInformation,
    chargeInformation: {
      firstMonthRent: item?.charge_information?.first_month_rent || 0,
      securityDeposit: item?.charge_information?.security_deposit || 0,
      brokerFee: item?.charge_information?.broker_fee || 0,
      applicationFee: item?.charge_information?.application_fee || 0,
      extraFee: item?.charge_information?.extra_fee || [],
    },
    utilityInformation: {
      gas: item?.utility_information?.gas || false,
      sewage: item?.utility_information?.sewage || false,
      electricity: item?.utility_information?.electricity || false,
      internet: item?.utility_information?.internet || false,
      water: item?.utility_information?.water || false,
      garbage: item?.utility_information?.garbage || false,
      cable: item?.utility_information?.cable || false,
      satelliteTv: item?.utility_information?.satellite_tv || false,
      otherUtilities: item?.utility_information?.other_utilities || null,
    },
    statisticInformation: {
      views: item?.statistic_information?.views || 0,
      saves: item?.statistic_information?.saves || 0,
      applications: item?.statistic_information?.applications || 0,
    },
    documentInformation: {
      documents: item?.document_information?.documents || [],
    } as DocumentsInformation,
    placeInformation: {
      places:
        item?.related_place_information?.places?.map((place) => ({
          id: place.id,
          image: place.image,
          name: place.name,
          address: place.address,
          url: place.url,
        })) || [],
    } as PlaceInformation,
    creationDate: item?.creation_date || '',
    lastUpdate: item?.last_update || '',
    isSaved: false,
  } as PropertyDetails;
};

/**
 * This method is used to convert API response to FE response which we used in other component.
 * The reason behind creating this helper function is If api response and add property context state has different structure.
 *
 * @param {Array} list
 * @returns {Array} Custom response
 */
const transFromPropertyToAddPropertyConext = (
  item: apiPropertyItem | undefined | null
) => {
  let netEffectiveFee = 0;
  if (item?.economic_information?.lease_concession) {
    netEffectiveFee = round(
      (item?.economic_information?.cost *
        (Number(item?.economic_information?.lease_term) -
          Number(item?.economic_information?.lease_months_free))) /
        Number(item?.economic_information?.lease_term)
    );
  }

  return {
    general_information: {
      agent_id: item?.general_information?.agent
        ? {
            id: item?.general_information?.agent?.id,
            generalInformation: {
              firstName:
                item?.general_information?.agent?.general_information
                  ?.first_name,
              middleName:
                item?.general_information?.agent?.general_information
                  ?.middle_name,
              lastName:
                item?.general_information?.agent?.general_information
                  ?.last_name,
              verified:
                item?.general_information?.agent?.general_information?.verified,
            },
            agentInformation: {
              profilePicture:
                item?.general_information?.agent?.agent_information
                  ?.profile_picture,
              companyName:
                item?.general_information?.agent?.agent_information
                  ?.company_name,
              bio: item?.general_information?.agent?.agent_information?.bio,
            },
          }
        : '',
      address: item?.general_information?.address.hasOwnProperty('coordinate')
        ? ({
            coordinates: {
              lat: item?.general_information?.address?.coordinate.latitude,
              lng: item?.general_information?.address?.coordinate.longitude,
            },
            primary: item?.general_information?.address?.primary,
            state: item?.general_information?.address?.state,
            neighborhood: item?.general_information?.address?.neighborhood,
            country: item?.general_information?.address?.country,
            postalCode: item?.general_information?.address?.postal_code,
            unitCode: item?.general_information?.address?.unit_code,
            route: item?.general_information?.address?.route,
            place_id: item?.general_information?.address?.place_id || '',
          } as Address)
        : {
            address: item?.general_information?.address
              ? combineAddress(item?.general_information?.address)
              : '',
            place_id:
              item?.general_information?.address?.primary ||
              item?.general_information?.address?.unit_code ||
              '',
          },
      property_name: item?.general_information?.property_name || '',
      apartment_number: 0,
      is_easy_apply: item?.general_information?.is_easy_apply || false,
      description: item?.general_information?.description || '',
      unit: item?.general_information?.address?.unit_code
        ? item?.general_information?.address?.unit_code + ''
        : '',
      floor: item?.general_information?.address?.floor
        ? item?.general_information?.address?.floor + ''
        : '',
      bedrooms:
        item?.general_information?.bedrooms !== undefined &&
        item?.general_information?.bedrooms !== null &&
        item?.general_information?.bedrooms <= 5
          ? item?.general_information?.bedrooms + ''
          : '',

      square_footage: item?.general_information?.square_footage || '',
      date_available: item?.general_information?.date_available || '',
      bedroom:
        item?.general_information?.bedrooms &&
        item?.general_information?.bedrooms < 5
          ? item?.general_information?.bedrooms + ''
          : '',
      bedroom_other:
        item?.general_information?.bedrooms &&
        item?.general_information?.bedrooms > 5
          ? item?.general_information?.bedrooms
          : '',
      bathrooms:
        item?.general_information?.bathrooms &&
        item?.general_information?.bathrooms <= 3.5
          ? item?.general_information?.bathrooms + ''
          : '',
      bathrooms_other:
        item?.general_information?.bathrooms &&
        item?.general_information?.bathrooms > 3.5
          ? item?.general_information?.bathrooms
          : '',
      time_posted: item?.general_information.time_posted || '',
      is_exclusive: item?.general_information?.is_exclusive,
      is_application_request_enabled:
        item?.general_information?.is_application_request_enabled,
    },
    economic_information: {
      lease_concession: item?.economic_information?.lease_concession
        ? item?.economic_information?.lease_concession + ''
        : false,
      lease_months_free: item?.economic_information?.lease_months_free
        ? item?.economic_information?.lease_months_free + ''
        : '',
      lease_term: item?.economic_information?.lease_term
        ? item?.economic_information?.lease_term + ''
        : '',
      net_effective_rent: netEffectiveFee || '',
      cost: item?.economic_information?.cost
        ? item?.economic_information?.cost + ''
        : '',
      updatedCost: item?.economic_information?.updated_cost
        ? item?.economic_information?.updated_cost + ''
        : 0,
      minCredit: item?.economic_information?.min_credit
        ? item?.economic_information?.min_credit + ''
        : '',
      minSalary: item?.economic_information?.min_salary
        ? item?.economic_information?.min_salary + ''
        : '',
      first_month_rent: item?.charge_information?.first_month_rent
        ? item?.charge_information?.first_month_rent + ''
        : '',
      security_deposite_amt: item?.charge_information?.security_deposit
        ? item?.charge_information?.security_deposit + ''
        : '',
      broker_fee:
        item?.charge_information?.broker_fee != null &&
        item?.charge_information?.broker_fee > -1
          ? item?.charge_information?.broker_fee + ''
          : '',
      application_fee:
        item?.charge_information?.application_fee != null &&
        item?.charge_information?.application_fee > -1
          ? item?.charge_information?.application_fee + ''
          : '',
      extra_fee:
        item?.charge_information?.extra_fee &&
        item?.charge_information?.extra_fee?.length > 0
          ? 'Yes'
          : 'No',
      extra_fee_fields: item?.charge_information?.extra_fee || [],
      brokerSign: '$',
      utilities: item?.utility_information
        ? Object.keys(item?.utility_information)
            .filter(
              (key) =>
                item?.utility_information.hasOwnProperty(key) &&
                item?.utility_information[
                  key as keyof typeof item.utility_information
                ]
            )
            .map((key) => EditUtility[key as keyof typeof EditUtility])
        : [],
      extra_utilities: item?.utility_information?.other_utilities || '',
    },
    image_information: {
      images:
        item?.image_information?.images?.map((image) => ({
          image: image.image || '',
        })) || [],
    } as ImageInfoInterface,
    images: {
      images:
        item?.image_information?.images?.map((image) => ({
          image: image.image || '',
          id: image.id || '',
        })) || [],
    } as ImageInfoInterface,
    amenity_information: {
      amenities:
        item?.amenity_information?.amenities?.map((amenity) => ({
          type: amenity.type || '',
          included: amenity.included || false,
        })) || [],
    } as AmenityInformation,
    charge_information: {
      first_month_rent: item?.charge_information?.first_month_rent || 0,
      security_deposit: item?.charge_information?.security_deposit || 0,
      broker_fee: item?.charge_information?.broker_fee || 0,
      application_fee: item?.charge_information?.application_fee || 0,
      extra_fee: item?.charge_information?.extra_fee || [],
    },
    utility_information: {
      gas: item?.utility_information?.gas || false,
      sewage: item?.utility_information?.sewage || false,
      electricity: item?.utility_information?.electricity || false,
      internet: item?.utility_information?.internet || false,
      water: item?.utility_information?.water || false,
      garbage: item?.utility_information?.garbage || false,
      cable: item?.utility_information?.cable || false,
      satellite_tv: item?.utility_information?.satellite_tv || false,
      other_utilities: item?.utility_information?.other_utilities || null,
    },
    statisticInformation: {
      views: item?.statistic_information?.views || 0,
      saves: item?.statistic_information?.saves || 0,
      applications: item?.statistic_information?.applications || 0,
    },
    document_information: {
      documents: item?.document_information?.documents || [],
    } as DocumentsInformation,
    related_place_information: {
      places:
        item?.related_place_information?.places?.map((place) => ({
          id: place.id,
          image: place.image,
          name: place.name,
          address: place.address,
          url: place.url || '',
        })) || [],
    } as PlaceInformation,
    creationDate: item?.creation_date || '',
    lastUpdate: item?.last_update || '',
  } as Partial<AddPropertyPayload>;
};

const translatePropertyAddPropertyAddress = (
  address: apiAgentPropertiesAddress
) => {
  return address.hasOwnProperty('coordinate')
    ? ({
        coordinates: {
          lat: address?.coordinate.latitude,
          lng: address?.coordinate.longitude,
        },
        primary: address?.primary,
        state: address?.state,
        neighborhood: address?.neighborhood,
        country: address?.country,
        postalCode: address?.postal_code,
        unitCode: address?.unit_code,
        route: address?.route,
      } as Address)
    : {
        address: address ? combineAddress(address) : '',
        place_id: address?.primary || address?.unit_code || '',
      };
};

/**
 * This method is used to convert the Property context state to create property api's payload.
 * The reason behind creating this helper function is if Context state structure is not same with property preview dialog then
 * It didn't effect on the property preview dialog box so.
 * @param {Array} list
 * @returns {Array} Custom response
 */
const transformCreatePropertyPayloadItem = (
  item: CreatePropertyPayload | undefined | null
) => {
  return {
    id: item?.id || 0,
    generalInformation: {
      agent: item?.general_information?.agent_id || 0,
      address: {
        address: item?.general_information?.address?.address || '',
        place_id: item?.general_information?.address?.place_id || '',
        address_place_id:
          item?.general_information?.address?.address_place_id || '',
        primary: item?.general_information?.address?.primary || '',
        state: item?.general_information?.address?.state || '',
        neighborhood: item?.general_information?.address?.neighborhood || '',
        country: item?.general_information?.address?.country || '',
        unitCode: item?.general_information?.address?.unit_code || '',
        route: item?.general_information?.address?.route || '',
        postalCode: item?.general_information?.address?.postal_code || '',
      },
      unitCode: item?.general_information?.unit || null,
      bedrooms: Number(item?.general_information?.bedrooms) || 0,
      cost: item?.economic_information?.cost || 0,
      bathrooms: item?.general_information?.bathrooms || 0,
      squareFootage: item?.general_information?.square_footage || 0,
      propertyName: item?.general_information?.property_name || '',
      dateAvailable: item?.general_information?.date_available || '',
      description: item?.general_information?.description || '',
      timePosted: item?.general_information?.time_posted || '',
      isEasyApply: item?.general_information?.is_easy_apply || false,
      isExclusive: item?.general_information?.is_exclusive,
    },
    economicInformation: {
      cost: item?.economic_information?.cost || 0,
      updatedCost: item?.economic_information?.updated_cost || 0,
      minCredit: item?.economic_information?.min_credit || 0,
      minSalary: item?.economic_information?.min_salary || 0,
      leaseConcession: item?.economic_information?.lease_concession || false,
      leaseMonthsFree: item?.economic_information?.lease_months_free || '',
      leaseTerm: item?.economic_information?.lease_term || '',
      netEffectiveRent: item?.economic_information?.net_effective_rent || 0,
    },
    imageInformation: {
      images:
        item?.image_information?.images?.map((image) => ({
          image: image.image || '',
          rotation: image.rotation || 0,
        })) || [],
    },
    amenityInformation: {
      amenities:
        item?.amenity_information?.amenities?.map((amenity) => ({
          type: amenity.type || '',
          included: amenity.included || false,
        })) || [],
    } as AmenityInformation,
    chargeInformation: {
      securityDeposit: item?.charge_information?.security_deposit || 0,
      brokerFee: item?.charge_information?.broker_fee || 0,
      applicationFee: item?.charge_information?.application_fee || 0,
      extraFee: item?.charge_information?.extra_fee || null,
    },
    utilityInformation: {
      gas: item?.utility_information?.gas || false,
      sewage: item?.utility_information?.sewage || false,
      electricity: item?.utility_information?.electricity || false,
      internet: item?.utility_information?.internet || false,
      water: item?.utility_information?.water || false,
      garbage: item?.utility_information?.garbage || false,
      cable: item?.utility_information?.cable || false,
      satelliteTv: item?.utility_information?.satellite_tv || false,
      otherUtilities: item?.utility_information?.other_utilities || null,
    },
    statisticInformation: {
      views: item?.statistic_information?.views || 0,
      saves: item?.statistic_information?.saves || 0,
      applications: item?.statistic_information?.applications || 0,
    },
    documentInformation: {
      documents: item?.document_information?.documents || [],
    } as DocumentsInformation,
    placeInformation: {
      places:
        item?.related_place_information?.places?.map((place) => ({
          id: place.id,
          image: place.image,
          name: place.name,
          address: place.address,
          url: place.url || '',
        })) || [],
    } as PlaceInformation,
    creationDate: item?.creation_date || '',
    lastUpdate: item?.last_update || '',
  } as PropertyPreviewDetails;
};

/**
 * Converts to renterHistoryPayload
 */
const transformRenterHistoryPayload = (item: RentalHistoryFormObject[]) => {
  return item.map((item) => {
    return {
      type: item?.type || 'RENTAL',
      landlord_name: item?.landlordName || '',
      landlord_email: item?.landlordEmail || '',
      landlord_phone_number:
        item?.landlordPhoneNumber?.replace(/[^0-9]/g, '') || '',
      start_date: item?.startDate
        ? formatDate(item.startDate, DATE_FORMATS.BACKEND_DATE_FORMAT)
        : '',
      end_date: item?.endDate
        ? formatDate(item.endDate, DATE_FORMATS.BACKEND_DATE_FORMAT)
        : undefined,
      current_address: item?.currentAddress || '',
      address_place_id: item?.addressPlaceId || '',
      still_living_here: item?.stillLivingHere || false,
      mortgage: item?.mortgage || 0,
      rent_payment: item?.rentPayment || 0,
    };
  });
};

/**
 * This method is used to convert API response to FE response which we used in other component.
 * The reason behind creating this helper function is If api missing key then FE will not break.
 *
 * @param {Array} profile
 * @returns {Array} Custom response
 */
const transformRenterProfile = (
  profile: RenterApiProfile | undefined | null
): RenterProfile | null => {
  if (!profile) return null;

  return {
    id: profile?.id,
    relatedAgent: profile?.related_agent || '',
    agentInformation: {
      bio: profile?.agent_information?.bio || '',
      companyName: profile?.agent_information?.company_name || '',
      profilePicture: profile?.agent_information?.profile_picture || '',
      profilePictureThumbnail:
        profile?.agent_information?.profile_picture_thumbnail || '',
      companyPrimaryAddress:
        profile?.agent_information?.company_primary_address || '',
      companyAddressPlaceId:
        profile?.agent_information?.company_address_placeid || '',
      companyAddressLineTwo:
        profile?.agent_information?.company_address_line_two || '',
      licenseNumber: profile?.agent_information?.license_number || '',
      aiEnabled: profile?.agent_information?.ai_enabled || false,
      manualApproved: profile?.agent_information?.manually_approved || false,
      profileUrlIdentifier:
        profile?.agent_information?.profile_url_identifier || '',
      nylasGrant: profile?.agent_information?.nylas_grant || false,
    },
    landlordInformation: {
      bio: profile?.landlord_information?.bio || '',
      companyName: profile?.landlord_information?.company_name || '',
      companyPrimaryAddress:
        profile?.landlord_information?.company_primary_address || '',
      companyAddressPlaceId:
        profile?.landlord_information?.company_address_placeid || '',
      companyAddressLineTwo:
        profile?.landlord_information?.company_address_line_two || '',
      profilePicture: profile?.landlord_information?.profile_picture || '',
      profilePictureThumbnail:
        profile?.landlord_information?.profile_picture_thumbnail || '',
      manualApproved: profile?.landlord_information?.manually_approved || false,
      relatedAgent: profile?.related_agent || '',
      profileUrlIdentifier:
        profile?.landlord_information?.profile_url_identifier || '',
      nylasGrant: profile?.landlord_information?.nylas_grant || false,
    },
    generalInformation: {
      firstName: profile?.general_information?.first_name || '',
      middleName: profile?.general_information?.middle_name || '',
      lastName: profile?.general_information?.last_name || '',
      phoneNumber: profile?.general_information?.phone_number,
      verified: profile?.general_information?.verified,
      email: profile?.general_information?.email,
      password: profile?.general_information?.password,
      notificationsTokenIos:
        profile?.general_information?.notifications_token_ios,
      notificationsTokenAndroid:
        profile?.general_information?.notifications_token_android,
      notificationsTokenWeb:
        profile?.general_information?.notifications_token_web,
      verifiedSms: profile?.general_information?.verified_sms || false,
      verifiedEmail: profile?.general_information?.verified_email || false,
      hasActiveSubscription:
        profile?.general_information?.has_active_subscription,
      currentSubscription: profile?.general_information?.current_subscription,
      paymentInstrumentDetails:
        profile?.general_information?.payment_instrument_details,
      isEmailNotificationsEnabled:
        profile?.general_information?.is_email_notifications_enabled,
    },
    renterInformation: {
      financialInformation: {
        creditInformation: {
          reportedCredit:
            profile?.renter_information?.financial_information
              ?.credit_information?.reported_credit,
          ficoScore:
            profile?.renter_information?.financial_information
              ?.credit_information?.fico_score,
          transunionScreeningId:
            profile?.renter_information?.financial_information
              ?.credit_information?.transunion_screening_id,
          reportFile:
            profile?.renter_information?.financial_information
              ?.credit_information?.report_file,
          expiryDate:
            profile?.renter_information?.financial_information
              ?.credit_information?.expiry_date,
          creditCheckStatus:
            profile?.renter_information?.financial_information
              ?.credit_information?.credit_check_status,
          transunionStatus:
            profile?.renter_information?.financial_information
              ?.credit_information?.transunion_status,
          paymentTransferId:
            profile?.renter_information?.financial_information
              ?.credit_information?.payment_transfer_id,
          dateOfBirth:
            profile?.renter_information?.financial_information
              ?.credit_information?.date_of_birth,
        },
        salaryInformation: {
          reportedSalary:
            profile?.renter_information?.financial_information
              ?.salary_information?.reported_salary,
          determinedIncome:
            profile?.renter_information?.financial_information
              ?.salary_information?.determined_income,
          plaidVerificationPublicToken:
            profile?.renter_information?.financial_information
              ?.salary_information?.plaid_verification_public_token,
        },
        employmentInformation: {
          timeAtWork:
            profile?.renter_information?.financial_information
              ?.employment_information?.time_at_work,
          location:
            profile?.renter_information?.financial_information
              ?.employment_information?.location,
          lengthOfCommute:
            profile?.renter_information?.financial_information
              ?.employment_information?.length_of_commute,
          commuteMethod:
            profile?.renter_information?.financial_information
              ?.employment_information?.commute_method,
          employerAddressPlaceId:
            profile?.renter_information?.financial_information
              ?.employment_information?.employer_address_place_id,
          employerEmail:
            profile?.renter_information?.financial_information
              ?.employment_information?.employer_email,
          employerName:
            profile?.renter_information?.financial_information
              ?.employment_information?.employer_name,
          employerPrimaryAddress:
            profile?.renter_information?.financial_information
              ?.employment_information?.employer_primary_address,
          employmentLengthInMonths:
            Number(
              profile?.renter_information?.financial_information
                ?.employment_information?.employment_length_in_months
            ) || 0,
          employmentLengthInYears:
            Number(
              profile?.renter_information?.financial_information
                ?.employment_information?.employment_length_in_years
            ) || 0,
          jobTitle:
            profile?.renter_information?.financial_information
              ?.employment_information?.job_title || '',
          isUnemployed:
            profile?.renter_information?.financial_information
              ?.employment_information?.is_unemployed || false,
          unemploymentReason:
            profile?.renter_information?.financial_information
              ?.employment_information?.unemployment_reason || '',
        },
      },
      neighborhoodPreferenceInformation: {
        nearbyParks:
          profile?.renter_information?.neighborhood_preference_information
            ?.nearby_parks,
        schoolRankings:
          profile?.renter_information?.neighborhood_preference_information
            ?.school_rankings,
        pointsOfInterest:
          profile?.renter_information?.neighborhood_preference_information
            ?.points_of_interest,
        commuteTime:
          profile?.renter_information?.neighborhood_preference_information
            ?.commute_time,
      },
      amenityInformation: profile?.renter_information?.amenity_information,
      identityInformation: {
        photoId: profile?.renter_information?.identity_information?.photo_id,
        rentalHistory:
          profile?.renter_information?.identity_information?.rental_history,
      },
      rentalHistories: (
        profile?.renter_information?.rental_histories || []
      ).map((history) => {
        return {
          type: history?.type || 'RENTAL',
          landlordName: history?.landlord_name || '',
          landlordEmail: history?.landlord_email || '',
          landlordPhoneNumber: history?.landlord_phone_number || '',
          startDate: history?.start_date || '',
          endDate: history?.end_date || '',
          currentAddress: history?.current_address || '',
          addressPlaceId: history?.address_place_id || '',
          stillLivingHere: history?.still_living_here || false,
          mortgage: +history?.mortgage > 0 ? history?.mortgage : '',
          rentPayment: +history?.rent_payment > 0 ? history?.rent_payment : '',
        };
      }),
      documentInformation: (
        profile?.renter_information?.document_information || []
      ).map((document) => {
        return {
          ...document,
          id: String(document.id),
          uploadDate: document.upload_date,
        };
      }),
      kidsAndPets: {
        totalOccupants: Number(
          profile?.renter_information?.household_information === null ||
            profile?.renter_information?.household_information
              ?.total_occupants === null
            ? 1
            : profile?.renter_information?.household_information
                ?.total_occupants
        ),
        smallDogs: Number(
          profile?.renter_information?.household_information?.small_dogs
        ),
        mediumDogs: Number(
          profile?.renter_information?.household_information?.medium_dogs
        ),
        largeDogs: Number(
          profile?.renter_information?.household_information?.large_dogs
        ),
        cats: Number(profile?.renter_information?.household_information?.cats),
        otherPets: Number(
          profile?.renter_information?.household_information?.other_pets
        ),
      },
    },
    creationDate: profile?.creation_date,
    lastUpdate: profile?.last_update,
  };
};

/**
 * This method is used to create the initial edit renter profile object
 * @param {RenterProfile | undefined | null} profile
 * @returns {InitialEditRenterValues} Custom response
 */
const transformEditRenterProfile = (
  profile: RenterProfile | undefined | null
) => {
  return {
    first_name: profile?.generalInformation?.firstName ?? '',
    middle_name: profile?.generalInformation?.middleName ?? '',
    last_name: profile?.generalInformation?.lastName ?? '',
    phone_number: profile?.generalInformation?.phoneNumber ?? '',
    email: profile?.generalInformation?.email ?? '',
    password: '',
    confirm_password: '',
    location:
      profile?.renterInformation?.financialInformation?.employmentInformation
        ?.location ?? '',
    time:
      getHHMMFromTime(
        String(
          profile?.renterInformation?.financialInformation
            ?.employmentInformation?.timeAtWork
        )
      ) ?? '08:00',
    timeAbb:
      getAMPMFromTime(
        String(
          profile?.renterInformation?.financialInformation
            ?.employmentInformation?.timeAtWork
        )
      ) ?? 'AM',
    commuteMethod:
      lowerCase(
        profile?.renterInformation?.financialInformation?.employmentInformation
          ?.commuteMethod
      ) ?? '',
    timeSecond: profile?.renterInformation?.financialInformation
      ?.employmentInformation?.lengthOfCommute
      ? String(
          profile?.renterInformation?.financialInformation
            ?.employmentInformation?.lengthOfCommute
        )
      : '15',
    reported_salary: String(
      !profile?.renterInformation?.financialInformation?.salaryInformation
        ?.reportedSalary
        ? 0
        : profile?.renterInformation?.financialInformation?.salaryInformation
            ?.reportedSalary
    ),
    reported_credit: String(
      !profile?.renterInformation?.financialInformation?.creditInformation
        ?.reportedCredit
        ? 0
        : profile?.renterInformation?.financialInformation?.creditInformation
            ?.reportedCredit
    ),
    amenities: profile?.renterInformation?.amenityInformation?.amenities?.length
      ? profile?.renterInformation?.amenityInformation?.amenities?.map(
          (amenity: Amenity) => amenity?.type
        )
      : [],
  } as InitialEditRenterValues;
};

/**
 * This method is used to create the initial edit renter profile object
 * @param {RenterProfile | undefined | null} profile
 * @returns {InitialEditRenterValues} Custom response
 */
const transformEditAgentProfile = (
  profile: RenterProfile | undefined | null
) => {
  return {
    first_name:
      profile?.generalInformation?.firstName ??
      profile?.generalInformation?.firstName ??
      '',
    middle_name: profile?.generalInformation?.middleName ?? '',
    last_name: profile?.generalInformation?.lastName ?? '',
    company_address:
      (profile?.agentInformation?.companyPrimaryAddress ||
        profile?.landlordInformation?.companyPrimaryAddress) ??
      '',
    company_address_place_id:
      (profile?.agentInformation?.companyAddressPlaceId ||
        profile?.landlordInformation?.companyAddressPlaceId) ??
      '',
    company_name:
      (profile?.agentInformation?.companyName ||
        profile?.landlordInformation?.companyName) ??
      '',
    company_address_line_two:
      (profile?.agentInformation?.companyAddressLineTwo ||
        profile?.landlordInformation?.companyAddressLineTwo) ??
      '',
    license_number: profile?.agentInformation?.licenseNumber ?? '',
    phone_number: profile?.generalInformation?.phoneNumber ?? '',
    email: profile?.generalInformation?.email ?? '',
    bio:
      (profile?.agentInformation?.bio || profile?.landlordInformation?.bio) ??
      '',
    password: '',
    confirm_password: '',
    terms: true,
  } as InitialAgentRegisterValues;
};

/**
 * This method is used to create payload for patch renter profile api
 * @param {InitialEditRenterValues} profile
 * @returns {InitialEditRenterValues} Custom response
 */
const transformStateToPayloadProfile = (profile: InitialEditRenterValues) => {
  return {
    general_information: {
      first_name: profile?.first_name || '',
      middle_name: profile?.middle_name || '',
      last_name: profile?.last_name || '',
      phone_number: profile?.phone_number || '',
    },
    renter_information: {
      financial_information: {
        credit_information: {
          reported_credit:
            profile?.reported_credit && Number(profile?.reported_credit) > 300
              ? Number(profile?.reported_credit)
              : null,
        },
        salary_information: {
          reported_salary:
            profile?.reported_salary && Number(profile?.reported_salary) > 0
              ? Number(profile?.reported_salary)
              : null,
        },
        employment_information: {
          time_at_work: profile.time ?? null,
          location: profile.location ?? null,
          length_of_commute: Number(profile.timeSecond) ?? null,
          commute_method: capitalize(profile.commuteMethod) ?? null,
        },
      },
      amenity_information: {
        amenities: profile?.amenities.length
          ? profile?.amenities.map((amenity: string) => ({
              type: amenity,
              included: true,
            }))
          : [],
      },
    },
  } as Partial<RegisterRenterPayload>;
};

/**
 * This method is used to create payload for patch agent profile api
 * @param {AgentRegisterEditValues} profile
 * @returns {InitialEditRenterValues} Custom response
 */
const transformStateToPayloadAgentProfile = (
  profile: AgentRegisterEditValues,
  isEdit: boolean
) => {
  const payload = isEdit
    ? ({
        general_information: {
          first_name: profile.first_name || '',
          middle_name: profile.middle_name || '',
          last_name: profile.last_name || '',
          // phone_number: profile.phone_number.replace(/[^0-9]/g, '') || '',
          verified: false,
          notifications_token: 'test',
        },
        agent_information: {
          bio: profile?.bio || '',
          profile_picture: profile.file.base64 || '',
          company_name: profile.company_name || '',
          license_number: profile.license_number || '',
          company_address_line_two: profile.company_address_line_two || '',
        },
      } as AgentRegisterPayload)
    : ({
        general_information: {
          first_name: profile.first_name || '',
          middle_name: profile.middle_name || '',
          last_name: profile.last_name || '',
          phone_number: profile.phone_number.replace(/[^0-9]/g, '') || '',
          verified: false,
          email: profile.email || '',
          password: profile.password || '',
          notifications_token: 'test',
        },
        agent_information: {
          license_number: profile.license_number || '',
          bio: profile?.bio || '',
          profile_picture: profile.file.base64 || '',
          company_address_placeid: profile.company_address_place_id || '',
          company_primary_address: profile.company_address || '',
          company_name: profile.company_name || '',
          company_address_line_two: profile.company_address_line_two || '',
        },
      } as AgentRegisterPayload);

  if (!profile.company_address_line_two && payload.agent_information) {
    delete payload.agent_information.company_address_line_two;
  }

  if (!profile?.file?.file && payload?.agent_information) {
    delete payload.agent_information.profile_picture;
  }

  if (!profile.license_number && payload.agent_information) {
    delete payload.agent_information.license_number;
  }

  return payload;
};

/**
 * This method is used to create payload for patch agent profile api
 * @param {AgentRegisterEditValues} profile
 * @returns {InitialEditRenterValues} Custom response
 */
const transformStateToPayloadLandlordProfile = (
  profile: AgentRegisterEditValues,
  isEdit: boolean
) => {
  const payload = {
    general_information: {
      first_name: profile.first_name || '',
      middle_name: profile.middle_name || '',
      last_name: profile.last_name || '',
      phone_number: profile.phone_number.replace(/[^0-9]/g, '') || '',
      verified: false,
      email: profile.email || '',
      password: profile.password || '',
      notifications_token: 'test',
    },
    landlord_information: {
      bio: profile?.bio || '',
      profile_picture: profile.file.base64 || '',
      company_address_placeid: profile.company_address_place_id || '',
      company_name: profile.company_name || '',
      company_address_line_two: profile.company_address_line_two || '',
      company_primary_address: profile.company_address || '',
    },
  } as AgentRegisterPayload;

  if (!profile.company_address_line_two && payload.landlord_information) {
    delete payload.landlord_information.company_address_line_two;
  }

  if (isEdit && payload.general_information.phone_number) {
    delete payload.general_information.phone_number;
  }

  if (isEdit && payload.general_information.email) {
    delete payload.general_information.email;
  }

  if (!profile?.file?.file && payload?.landlord_information) {
    delete payload.landlord_information.profile_picture;
  }

  if (isEdit && 'password' in payload.general_information) {
    delete payload.general_information.password;
  }

  return payload;
};

/**
 * Combine address field as string
 *
 * @param {Object} address
 * @returns {String}
 */
const combineAddress = (address: Partial<Address> | undefined) => {
  const { primary = '', state = '', postalCode = '' } = address || {};

  const addressArr: string[] = [];

  if (primary) addressArr.push(primary);
  if (state) addressArr.push(state);
  if (postalCode) addressArr.push(postalCode);

  return addressArr.join(', ');
};

/**
 * Combine address field as string
 *
 * @param {Object} address
 * @returns {String}
 */
const combineAddressForFinix = (
  address: FinixAddress | undefined,
  isOnlyLine1 = false,
  isIgnoreLine1 = false
) => {
  const { line1, line2, city, region, postal_code } = address || {};

  const addressArr: string[] = [];

  if (line1 && !isIgnoreLine1) addressArr.push(line1);

  if (isOnlyLine1) {
    return line1;
  }

  if (line2) addressArr.push(line2);
  if (city) addressArr.push(city);
  if (region) addressArr.push(region);
  if (postal_code) addressArr.push(postal_code);

  return addressArr.join(', ');
};

/**
 * Combine address field as string
 *
 * @param {Object} address
 * @returns {String}
 */
const splitAddress = (
  address: Partial<Address> | undefined,
  showRoute?: boolean
) => {
  const {
    neighborhood = '',
    primary = '',
    state = '',
    postalCode = '',
    unitCode = '',
    route = '',
  } = address || {};

  const addressArr: string[] = [];
  const stateCityArr: string[] = [];

  showRoute
    ? route && addressArr.push(route)
    : (primary && addressArr.push(primary),
      unitCode && addressArr.push(unitCode));

  if (neighborhood) stateCityArr.push(neighborhood);
  if (state) stateCityArr.push(state);
  if (postalCode) stateCityArr.push(postalCode);

  return { title: addressArr.join(' #'), subTitle: stateCityArr.join(', ') };
};

/**
 * Combine address field as string
 *
 * @param {Object} address
 * @returns {String}
 */
const splitAddressWithString = (
  address: string | undefined,
  unitCode: string | undefined
) => {
  const title: string[] = [];
  let subTitle = '';
  if (address) {
    const parts = address.split(',');
    const primaryAdd = parts[0];
    title.push(String(primaryAdd));
    subTitle = parts.slice(1).join(',').trim() ?? '';
  }

  if (unitCode) {
    title.push(String(unitCode));
  }

  return { title: title.join(' #'), subTitle: subTitle };
};

/**
 * Build url to passing filters
 */

const propertyURLFilterBuilder = (filters: PropertyFilter, url: string) => {
  // Convert the URL string to a URL object
  const urlObject = new URL(url, window.location.origin);
  const paramsToRemove = [
    'keyword',
    'is_easy_apply',
    'bedrooms',
    'bathrooms',
    'estimated_cost',
    'cost_min',
    'cost_max',
    'square_footage_max',
    'square_footage_min',
    'upfront_cost',
    'estimated_cost',
    'no_fee',
  ];
  for (const param of paramsToRemove) {
    urlObject.searchParams.delete(param);
  }
  if (filters.keyword) {
    urlObject.searchParams.set('keyword', encodeURIComponent(filters.keyword));
  }
  if (filters.isEasyApply) {
    urlObject.searchParams.set('is_easy_apply', 'true');
  }
  if (filters.bedrooms) {
    urlObject.searchParams.set('bedrooms', filters.bedrooms.toString());
  }
  if (filters.bathrooms) {
    urlObject.searchParams.set('bathrooms', filters.bathrooms.toString());
  }
  if (filters.estimatedCost) {
    urlObject.searchParams.set(
      'estimated_cost',
      filters.estimatedCost.toString()
    );
  }
  if (filters.costMax) {
    urlObject.searchParams.set('cost_min', filters.costMin.toString());
    urlObject.searchParams.set('cost_max', filters.costMax.toString());
  }
  if (filters.squareFootageMax) {
    urlObject.searchParams.set(
      'square_footage_max',
      filters.squareFootageMax.toString()
    );
    urlObject.searchParams.set(
      'square_footage_min',
      filters.squareFootageMin.toString()
    );
  }
  if (filters.upfrontCost) {
    urlObject.searchParams.set('upfront_cost', filters.upfrontCost.toString());
  }
  if (filters.estimatedCost) {
    urlObject.searchParams.set(
      'estimated_cost',
      filters.estimatedCost.toString()
    );
  }
  if (filters.moveIn) {
    urlObject.searchParams.set('move_in', filters.moveIn);
  }
  if (filters.amenity?.length) {
    urlObject.searchParams.set('amenities', filters.amenity.toString());
  }
  if (filters.noFee) {
    urlObject.searchParams.set('no_fee', 'true');
  }

  // Convert the URL object back to a string
  const queryString = urlObject.search.slice(1);

  return `?${queryString}`;
};

const mapBoundUrlBuilder = (
  searchParams: URLSearchParams,
  coords: number[]
) => {
  let updatedQueryString = '';

  // Iterate through the existing parameters and build the query string
  for (const key of searchParams.keys()) {
    const value = searchParams.get(key);
    if (key === 'bound_coordinates') {
      // If 'bound_coordinates' parameter exists, replace its value
      updatedQueryString += `${key}=${coords.toString()}&`;
    } else {
      // Otherwise, keep the existing parameter
      updatedQueryString += `${key}=${value}&`;
    }
  }

  // If 'bound_coordinates' doesn't exist, add it to the query string
  if (!searchParams.has('bound_coordinates')) {
    updatedQueryString += `bound_coordinates=${coords.toString()}&`;
  }

  // Remove the trailing '&' character
  updatedQueryString = `?${updatedQueryString.slice(0, -1)}`;

  return updatedQueryString;
};

const sortUrlBuilder = (searchParams: URLSearchParams, sortBy: string) => {
  let updatedQueryString = '';

  // Iterate through the existing parameters and build the query string
  for (const key of searchParams.keys()) {
    const value = searchParams.get(key);
    if (key === 'sort_by') {
      // If 'bound_coordinates' parameter exists, replace its value
      updatedQueryString += `${key}=${sortBy}&`;
    } else {
      // Otherwise, keep the existing parameter
      updatedQueryString += `${key}=${value}&`;
    }
  }

  // If 'bound_coordinates' doesn't exist, add it to the query string
  if (!searchParams.has('sort_by')) {
    updatedQueryString += `sort_by=${sortBy}&`;
  }

  // Remove the trailing '&' character
  updatedQueryString = `?${updatedQueryString.slice(0, -1)}`;
  return updatedQueryString;
};

function removeQueryParam(url: string, param: string) {
  // Create a regular expression pattern to match the query parameter
  const pattern = new RegExp(`[?&]${param}=[^&]*(&|$)`, 'g');

  // Replace the matched pattern with an empty string to remove the parameter
  const updatedUrl = url.replace(pattern, '');

  // Remove any trailing "?" or "&" characters if they exist
  return updatedUrl.startsWith('?') ? updatedUrl : `?${updatedUrl}`;
}

/**
 * Build url to passing filters
 */
const propertyURLSearchBuilder = (
  filters: AddressAutoSearch[],
  url: string
) => {
  const urlObject = new URL(url, window.location.origin);
  const paramsToRemove = [
    'neighborhood',
    'zip_code',
    'exact_address',
    'address_label',
    'bound_coordinates',
  ];
  for (const param of paramsToRemove) {
    urlObject.searchParams.delete(param);
  }

  const urlFilters = filters
    .map((filter) => {
      return filter.main_text;
    })
    .join('|');

  if (filters.length > 0 && filters[0].types !== 'exact_address')
    urlObject.searchParams.set(filters[0].types, urlFilters);

  if (filters.length > 0 && filters[0].types === 'exact_address') {
    urlObject.searchParams.set(filters[0].types, urlFilters);
    urlObject.searchParams.set('address_label', filters[0].address);
  }

  const queryString = urlObject.search.slice(1);
  return `?${queryString}`;
};

/**
 * convert url_filter query to filter object
 */
const convertQueryStringToObject = (queryString: string) => {
  const params = new URLSearchParams(queryString);

  const filter: PropertyFilter = {
    bathrooms: parseFloat(params.get('bathrooms') ?? '0') || 0,
    bedrooms: parseFloat(params.get('bedrooms') ?? '0') || 0,
    costMax: parseFloat(params.get('cost_max') ?? '0') || 0,
    costMin: parseFloat(params.get('cost_min') ?? '0') || 0,
    isEasyApply: params.get('easyapply') === 'true',
    keyword: params.get('keyword') || '',
    limit: parseInt(params.get('limit') ?? '20') || 20,
    moveIn: params.get('move_in') || '',
    offset: parseInt(params.get('offset') ?? '0') || 0,
    amenity: JSON.parse(params.get('amenities') ?? '[]') || [],
    estimatedCost: 0,
    squareFootageMax: 0,
    squareFootageMin: 0,
    upfrontCost: 0,
    noFee: false,
  };

  return filter;
};

/**
 *  Add commas in number
 */
const formatNumberWithCommas = (number: number): string => {
  return number?.toLocaleString('en-US');
};

/**
 *  For format Dates
 */
const formatDate = (date: string, dateFormat: string): string => {
  if (!date) return '';

  return format(new Date(date), dateFormat);
};

/**
 *  For format Times
 */
const formatTime = (timeString: string): string => {
  const date = new Date(timeString);
  return format(date, TIME_FORMATS.H_MM_A);
};

const formatDateRelative = (date: string): string => {
  const formatRelativeLocale = {
    lastWeek: DATE_FORMATS.MM_DD_YYYY,
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: DATE_FORMATS.MM_DD_YYYY,
    other: DATE_FORMATS.MM_DD_YYYY,
  };

  const locale = {
    ...de,
    formatRelative: (token: string) =>
      formatRelativeLocale[token as keyof typeof formatRelativeLocale],
  };

  return formatRelative(new Date(date), new Date(), { locale });
};

/**
 * For the Date with Time
 * @param {string} date
 **/
const formatDateAndTime = (date: string) => {
  const isDate = formatDateRelative(date);
  const isTime = formatTime(date);
  return `${isDate} at ${isTime} `;
};

/**
 * This method is used to generate chart config for Up fort cost in detail modal.
 * @param {Object} chargeInformation
 * @param {Number} updatedCost
 * @returns {Object}
 */
const getUpFrontCostChartData = (
  chargeInformation: chargeInformation,
  updatedCost: number | null
) => {
  const copyOfChargeInformation = cloneDeep(chargeInformation);

  delete copyOfChargeInformation['extraFee'];

  let firstMonthCost = Object.values(copyOfChargeInformation)
    .filter((n) => n)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  chargeInformation?.extraFee?.forEach((fee) => {
    firstMonthCost += fee.amount;
  });

  const rent: number[] = Array.from({ length: 12 }, () => Number(updatedCost));

  const deposit: number[] = Array.from({ length: 12 }, (_, i) =>
    i == 0 ? Number(chargeInformation.securityDeposit) : 0
  );

  const brokerFee: number[] = Array.from({ length: 12 }, (_, i) =>
    i == 0 ? Number(chargeInformation.brokerFee) : 0
  );

  const appFee: number[] = Array.from({ length: 12 }, (_, i) =>
    i == 0 ? Number(chargeInformation.applicationFee) : 0
  );

  const totalDeposit = deposit.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const totalBrokerFee = brokerFee.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const totalAppFee = appFee.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  let totalOneTimeFees = 0;
  let totalMonthlyFees = 0;

  chargeInformation?.extraFee
    ?.filter((fee) => fee.fee_type === FEE_TYPES.ONE_TIME)
    .map((fee) => {
      totalOneTimeFees += Number(fee.amount);
    });

  chargeInformation?.extraFee
    ?.filter((fee) => fee.fee_type === FEE_TYPES.RECURRING)
    .map((fee) => {
      totalMonthlyFees += Number(fee.amount);
    });

  const oneTimeFee: number[] = Array.from({ length: 12 }, (_, i) =>
    i == 0 ? totalOneTimeFees : 0
  );

  const monthlyFee: number[] = Array.from(
    { length: 12 },
    () => totalMonthlyFees
  );

  const config = {
    firstMonthCost: firstMonthCost + rent[0] || 0,
    totalMonthlyFees: totalMonthlyFees,
    chart: {
      height: '320px',
      type: 'column',
      style: {
        fontFamily: 'Poppins, sans-serif',
      },
    },
    legend: {
      layout: 'horizontal',
      verticalAlign: 'top',
      align: 'right',
      itemDistance: 9,
      padding: 0,
      itemMarginTop: 0,
      itemMarginBottom: 20,
      itemStyle: {
        lineHeight: '14px',
      },
      reversed: true,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: Array.from({ length: 12 }, (_, i) => `Month ${i + 1} `),
      crosshair: true,
      style: {
        stroke: '#eee',
      },
    },
    yAxis: {
      labels: {
        format: '${text}', // The $ is literally a dollar unit
      },
      min: 0,
      title: {
        text: '',
      },
      stackLabels: {
        enabled: true,
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
        formatter: function (this: any) {
          return formatCurrency(this.total, true);
        },
      },
    },
    tooltip: {
      formatter: function (): string[] {
        const tooltipTextArray = [];

        // Push Header
        tooltipTextArray.push(
          `<span style="font-size:13px;font-weight:600;">${this.x}</span><table style="margin-top:5px;">`
        );

        // List
        if ((this.points as Highcharts.Point[]) && this.points.length > 0) {
          this.points.forEach(
            (point: { series: { color: string; name: string }; y: number }) => {
              const pointText = `<tr><td style="padding:0">${
                point.series.name
              }: </td><td style="padding:0;color:${
                point.series.color
              };">&nbsp;&nbsp;${formatCurrency(point.y)}</td></tr>`;

              if (point.y > 0) tooltipTextArray.push(pointText);
            }
          );
        }

        // Push Footer
        tooltipTextArray.push(`</table>`);
        return tooltipTextArray;
      },
      shared: true,
      useHTML: true,
      points: [],
      x: '',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0.2,
        borderWidth: 0,
      },
      series: {
        pointWidth: 20,
      },
    },
    series: [
      {
        name: 'Rent',
        data: rent,
        color: '#006AF9',
      },
    ],
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  // Check if series is empty
  if (totalDeposit > 0)
    config.series.push({
      name: 'Deposit',
      data: deposit,
      color: '#09C0F9',
    });

  if (totalBrokerFee > 0)
    config.series.push({
      name: 'Broker Fee',
      data: brokerFee,
      color: '#72DFFF',
    });

  if (totalAppFee > 0)
    config.series.push({
      name: 'App. Fee',
      data: appFee,
      color: '#CCCCFF',
    });

  if (totalMonthlyFees > 0)
    config.series.push({
      name: 'Monthly Fee(s)',
      data: monthlyFee,
      color: '#FF21F6',
    });

  if (totalOneTimeFees > 0)
    config.series.push({
      name: 'One-Time Fee(s)',
      data: oneTimeFee,
      color: '#D70FF5',
    });

  config.series.reverse();

  return config;
};

/**
 * This method is used to validate time in 12 hour format.
 * @param {string} time
 * @returns {Boolean}
 */
const isValidTime = (time: string, meridiem: string) => {
  if (!time) return false;
  // Regex to check valid
  // time in 12-hour format

  // Uncomment this line when we have to check regex like 10:11 AM
  // const regex = new RegExp(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/);
  const regex = new RegExp(/((1[0-1]|0?[0-9]):([0-5][0-9]))/);

  const [hours] = time.split(':');
  if (meridiem === 'AM' && Number(hours) > 11) return false;

  return regex.test(time);
};

/**
 * This method is used to convert File to BlobURL.
 * @param {File} files
 * @returns {string[]}
 */
const transformFileToBlobURL = (files: File[]) => {
  const fileBlobURL: string[] = files.map((file) => URL.createObjectURL(file));
  return fileBlobURL;
};

/**
 * This method is used to generate the zeme-id format.
 * @param {number} id
 * @returns {string}
 */
const formatZemeId = (id: string) => {
  return id ? `#ZM-${id}` : `#ZM-0`;
};

const getApplicationStateAsPerRole = (
  role: string,
  states: ApplicationState[],
  currentState: string,
  applicantStatus?: string,
  isIncludedLandlord?: boolean
) => {
  let activeIndex = 1;
  const newStates = [...states].map((state) => {
    return {
      ...state,
      isCompleted: false,
    };
  });

  // Update isCompleted and label for application state for agent
  if (role === ZEME_USERS_ROLE.AGENT || role === ZEME_USERS_ROLE.LANDLORD)
    for (let i = 0; i < newStates.length; i++) {
      newStates[i].isCompleted = true;
      activeIndex = i + 1;

      if (
        newStates[i].value === APPLICATION_STATES_ENUM.LEASE_SENT ||
        newStates[i].value === APPLICATION_STATES_ENUM.LEASE_UPLOADED
      ) {
        newStates[i].label = APPLICATION_STATES_LABELS_ENUM.LEASE_SENT;
      }

      if (
        newStates[i].value ===
          APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS &&
        !isIncludedLandlord
      ) {
        newStates[i].label = APPLICATION_STATES_LABELS_ENUM.LEASE_SIGNED;
      }

      if (
        newStates[i].value ===
          APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS &&
        isIncludedLandlord
      ) {
        newStates[i].label = APPLICATION_STATES_LABELS_ENUM.APPLICANTS_SIGNED;
      }

      if (
        newStates[i].value === APPLICATION_STATES_ENUM.LEASE_SIGNED_LANDLORD &&
        isIncludedLandlord
      ) {
        newStates[i].label = APPLICATION_STATES_LABELS_ENUM.LANDLORD_SIGNED;
      }

      if (
        newStates[i].value ===
          APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS &&
        currentState === APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS &&
        isIncludedLandlord
      ) {
        newStates[i + 1].label =
          APPLICATION_STATES_LABELS_ENUM.AWAITING_SIGNATURE_LANDLORD;
      }

      if (newStates[i].value === APPLICATION_STATES_ENUM.PAYMENTS_REQUESTED) {
        newStates[i].label = APPLICATION_STATES_LABELS_ENUM.PAYMENT_REQUESTED;
      }

      if (newStates[i].value === APPLICATION_STATES_ENUM.PAYMENTS_RECEIVED) {
        newStates[i].label = APPLICATION_STATES_LABELS_ENUM.PAYMENTS_RECEIVED;
      }

      if (
        newStates[i].value === APPLICATION_STATES_ENUM.APPLICATION_SUBMITTED &&
        currentState === APPLICATION_STATES_ENUM.LEASE_UPLOADED &&
        newStates.length !== i + 1 &&
        newStates[i + 1].value === APPLICATION_STATES_ENUM.LEASE_SENT
      ) {
        newStates[i + 1].label = APPLICATION_STATES_LABELS_ENUM.LEASE_SENT;
        newStates[i + 1].buttonText = 'Open Draft';

        newStates[i].isCompleted = true;
        break;
      }

      if (
        newStates[i].value === APPLICATION_STATES_ENUM.APPLICATION_SUBMITTED &&
        currentState === APPLICATION_STATES_ENUM.IN_PROGRESS &&
        newStates.length !== i + 1
      ) {
        newStates[i].isCompleted = false;
        activeIndex = activeIndex - 1;
        break;
      }

      if (i + 1 === newStates.length) {
        if (currentState === APPLICATION_STATES_ENUM.CANCELLED)
          newStates[i].label = APPLICATION_STATES_LABELS_ENUM.CANCELLED;

        if (currentState === APPLICATION_STATES_ENUM.ACCEPTED)
          newStates[i].label =
            APPLICATION_STATES_LABELS_ENUM.APPLICATION_ACCEPTED;

        if (currentState === APPLICATION_STATES_ENUM.DENIED)
          newStates[i].label = APPLICATION_STATES_LABELS_ENUM.DENIED;
      }

      if (newStates[i].value == currentState && newStates.length !== i + 1) {
        // Change active label if needed if current state is active

        if (
          newStates[i + 1].value ===
            APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS &&
          !isIncludedLandlord
        )
          newStates[i + 1].label =
            APPLICATION_STATES_LABELS_ENUM.AWAITING_SIGNATURE;

        break;
      }
    }

  // Update isCompleted and label for application state for renter
  if (role === ZEME_USERS_ROLE.RENTER)
    for (let i = 0; i < newStates.length; i++) {
      newStates[i].isCompleted = true;
      activeIndex = i + 1;

      if (currentState === APPLICATION_STATES_ENUM.LEASE_UPLOADED)
        newStates[i].label =
          APPLICATION_STATES_LABELS_ENUM.APPLICATION_VERIFIED;

      if (newStates[i].value === APPLICATION_STATES_ENUM.LEASE_SENT) {
        newStates[i].label =
          APPLICATION_STATES_LABELS_ENUM.APPLICATION_VERIFIED;
      }

      if (
        (newStates[i].value === APPLICATION_STATES_ENUM.LEASE_SENT &&
          applicantStatus === APPLICATION_CO_APPLICANT_ENUM.SIGNED) ||
        (newStates[i].value === APPLICATION_STATES_ENUM.LEASE_SENT &&
          applicantStatus ===
            APPLICATION_CO_APPLICANT_ENUM.SIGNED_WITHOUT_WEBHOOK)
      ) {
        newStates[i + 1].label = APPLICATION_STATES_LABELS_ENUM.LEASE_SIGNED;
        newStates[i + 1].buttonText = '';
      }

      if (
        newStates[i].value === APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS
      ) {
        newStates[i].label = APPLICATION_STATES_LABELS_ENUM.LEASE_SIGNED;
      }

      if (newStates[i].value === APPLICATION_STATES_ENUM.PAYMENTS_REQUESTED) {
        newStates[i].label = APPLICATION_STATES_LABELS_ENUM.LEASE_VERIFIED;
      }

      if (newStates[i].value === APPLICATION_STATES_ENUM.PAYMENTS_RECEIVED) {
        newStates[i].label = APPLICATION_STATES_LABELS_ENUM.PAYMENT_PAID;
      }

      if (
        newStates[i].value ===
          APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS &&
        currentState === APPLICATION_STATES_ENUM.LEASE_SIGNED_LANDLORD &&
        newStates.length !== i + 1
      ) {
        break;
      }

      if (i + 1 === newStates.length) {
        if (currentState === APPLICATION_STATES_ENUM.CANCELLED)
          newStates[i].label = APPLICATION_STATES_LABELS_ENUM.CANCELLED;

        if (currentState === APPLICATION_STATES_ENUM.ACCEPTED)
          newStates[i].label = APPLICATION_STATES_LABELS_ENUM.MOVE_IN;

        if (currentState === APPLICATION_STATES_ENUM.DENIED)
          newStates[i].label = APPLICATION_STATES_LABELS_ENUM.DENIED;
      }

      if (
        newStates[i].value === APPLICATION_STATES_ENUM.APPLICATION_SUBMITTED &&
        currentState === APPLICATION_STATES_ENUM.LEASE_UPLOADED
      )
        break;

      if (newStates[i].value == currentState && newStates.length !== i + 1) {
        break;
      }
    }

  return { states: newStates, activeIndex };
};

/**
 *This is used to display application state label as per current application state
 * @param {String} state
 * @returns {String}
 */
const getStateLabelForRenter = (state: string, applicantStatus: string) => {
  if (state === APPLICATION_STATES_ENUM.UNAVAILABLE) {
    return APPLICATION_STATES_LABELS_ENUM.UNAVAILABLE;
  }

  if (
    (state === APPLICATION_STATES_ENUM.LEASE_SENT &&
      applicantStatus === APPLICATION_CO_APPLICANT_ENUM.SIGNED) ||
    (state === APPLICATION_STATES_ENUM.LEASE_SENT &&
      applicantStatus === APPLICATION_CO_APPLICANT_ENUM.SIGNED_WITHOUT_WEBHOOK)
  ) {
    return APPLICATION_STATES_LABELS_ENUM.LEASE_SIGNED;
  }

  if (applicantStatus === APPLICATION_CO_APPLICANT_ENUM.COMPLETED) {
    return APPLICATION_STATES_LABELS_ENUM.APPLICATION_SUBMITTED;
  }

  if (applicantStatus === APPLICATION_CO_APPLICANT_ENUM.INVITED) {
    return 'Invite Sent';
  }

  switch (state) {
    case APPLICATION_STATES_ENUM.CANCELLED:
      return APPLICATION_STATES_LABELS_ENUM.CANCELLED;
    case APPLICATION_STATES_ENUM.ACCEPTED:
      return APPLICATION_STATES_LABELS_ENUM.MOVE_IN;
    case APPLICATION_STATES_ENUM.DENIED:
      return APPLICATION_STATES_LABELS_ENUM.DENIED;
    case APPLICATION_STATES_ENUM.IN_PROGRESS:
      return APPLICATION_STATES_LABELS_ENUM.APPLICATION_IN_PROGRESS;
    case APPLICATION_STATES_ENUM.APPLICATION_SUBMITTED:
      return APPLICATION_STATES_LABELS_ENUM.APPLICATION_SUBMITTED;
    case APPLICATION_STATES_ENUM.LEASE_SENT:
    case APPLICATION_STATES_ENUM.LEASE_UPLOADED:
      return APPLICATION_STATES_LABELS_ENUM.SIGN_LEASE;
    case APPLICATION_STATES_ENUM.LEASE_SIGNED_LANDLORD:
      return APPLICATION_STATES_LABELS_ENUM.LANDLORD_SIGNED;
    case APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS:
      return APPLICATION_STATES_LABELS_ENUM.LEASE_SIGNED;
    default:
      return APPLICATION_STATES_LABELS_ENUM.APPLICATION_IN_PROGRESS;
  }
};

/**
 *This is used to display application state label as per current application state
 * @param {String} state
 * @returns {String}
 */
const getStateLabelForAgent = (state: string) => {
  switch (state) {
    case APPLICATION_STATES_ENUM.CANCELLED:
      return APPLICATION_STATES_LABELS_ENUM.CANCELLED;
    case APPLICATION_STATES_ENUM.ACCEPTED:
      return APPLICATION_STATES_LABELS_ENUM.APPLICATION_APPROVAL;
    case APPLICATION_STATES_ENUM.DENIED:
      return APPLICATION_STATES_LABELS_ENUM.DENIED;
    case APPLICATION_STATES_ENUM.IN_PROGRESS:
      return APPLICATION_STATES_LABELS_ENUM.APPLICATION_IN_PROGRESS;
    case APPLICATION_STATES_ENUM.APPLICATION_SUBMITTED:
      return APPLICATION_STATES_LABELS_ENUM.APPLICATION_SUBMITTED;
    case APPLICATION_STATES_ENUM.LEASE_SENT:
    case APPLICATION_STATES_ENUM.LEASE_UPLOADED:
      return APPLICATION_STATES_LABELS_ENUM.AWAITING_SIGNATURE;
    case APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS:
      return APPLICATION_STATES_LABELS_ENUM.LEASE_SIGNED;
    default:
      return APPLICATION_STATES_LABELS_ENUM.APPLICATION_IN_PROGRESS;
  }
};

/**
 * This method is used for the sorting in the application listing page.
 * @param {String} type
 * @returns {Array}
 */
const getApplicationSorting = (
  applications: AllApplication[],
  type: SORT_OPTIONS
): AllApplication[] => {
  switch (type) {
    case SORT_OPTIONS.NEWEST_TO_OLDEST:
      return [...applications].sort(
        (a, b) =>
          new Date(b.creation_date).getTime() -
          new Date(a.creation_date).getTime()
      );
    case SORT_OPTIONS.OLDEST_TO_NEWEST:
      return [...applications].sort(
        (a, b) =>
          new Date(a.creation_date).getTime() -
          new Date(b.creation_date).getTime()
      );
    case SORT_OPTIONS.INCOME_LOW_TO_HIGH:
      return [...applications].sort((a: AllApplication, b: AllApplication) => {
        const aReportedCredit = a.applicant_information.applicants.reduce(
          (totalCredit: number, applicant: Applicant) =>
            totalCredit +
            applicant.renter.renter_information.financial_information
              .credit_information.reported_credit,
          0
        );
        const bReportedCredit = b.applicant_information.applicants.reduce(
          (totalCredit: number, applicant: Applicant) =>
            totalCredit +
            applicant.renter.renter_information.financial_information
              .credit_information.reported_credit,
          0
        );
        return aReportedCredit - bReportedCredit;
      });
    case SORT_OPTIONS.INCOME_HIGH_TO_LOW:
      return [...applications].sort((a: AllApplication, b: AllApplication) => {
        const bReportedCredit = b.applicant_information.applicants.reduce(
          (totalCredit: number, applicant: Applicant) =>
            totalCredit +
            applicant.renter.renter_information.financial_information
              .credit_information.reported_credit,
          0
        );
        const aReportedCredit = a.applicant_information.applicants.reduce(
          (totalCredit: number, applicant: Applicant) =>
            totalCredit +
            applicant.renter.renter_information.financial_information
              .credit_information.reported_credit,
          0
        );
        return bReportedCredit - aReportedCredit;
      });

    default:
      return [...applications];
  }
};
/**
 * This method is used for the sorting the label in the application listing page.
 * @param {String} type
 * @returns {String}
 */
const getSortOptionLable = (type: SORT_OPTIONS) => {
  switch (type) {
    case SORT_OPTIONS.NEWEST_TO_OLDEST:
      return GET_SORT_APPLICATION_LABLE_ENUM.NEWEST_TO_OLDEST;
    case SORT_OPTIONS.OLDEST_TO_NEWEST:
      return GET_SORT_APPLICATION_LABLE_ENUM.OLDEST_TO_NEWEST;
    case SORT_OPTIONS.INCOME_LOW_TO_HIGH:
      return GET_SORT_APPLICATION_LABLE_ENUM.INCOME_LOW_TO_HIGH;
    case SORT_OPTIONS.INCOME_HIGH_TO_LOW:
      return GET_SORT_APPLICATION_LABLE_ENUM.INCOME_HIGH_TO_LOW;
    default:
      return GET_SORT_APPLICATION_LABLE_ENUM.NEWEST_TO_OLDEST;
  }
};

/**
 * This method is used for get the origin
 * @returns {String}
 */
const getOrigin = () => {
  const origin: string = window?.location?.origin || '';
  return origin;
};
/**
 * This method is used for get the origin
 * @returns {String}
 */
const getURLQueryString = () => {
  const search: string = window?.location?.search || '';
  return search;
};

/** This method is used for the AM/PM from the time.
 * @param {String} type
 * @returns {String}
 */
const getAMPMFromTime = (timeString: string) => {
  const match = timeString.match(/\b(?:AM|PM)\b/i);
  return match ? match[0] : null;
};

/**
 * This method is used for the time in the HH:MM formate from the string time.
 * @param {String} type
 * @returns {String}
 */
function getHHMMFromTime(time: string) {
  const strippedTime = time.replace(/\s*[APap][Mm]\s*$/, '');
  return strippedTime;
}

function getNameInitials(name: string) {
  const words = name.split(' ');

  // Initialize an empty string for initials
  let initials = '';

  // Iterate through the words and add the first letter of each word to the initials
  for (const word of words) {
    if (word.length > 0) {
      initials += word[0].toUpperCase();
    }
  }

  return initials;
}

/**
 * Calculates the height of the dialog based on the window height.
 *
 * @return {number} The calculated height of the dialog.
 */
const getDialogHeight = (options: {
  isAddPadding?: boolean;
  isIncludeHeader?: boolean;
  extraPaddingToRemove?: number;
}) => {
  const {
    isAddPadding = false,
    isIncludeHeader = true,
    extraPaddingToRemove = 0,
  } = options || {};
  return (
    window.innerHeight -
      (56 +
        (isIncludeHeader ? 81 : 0) +
        (isAddPadding ? 40 : 0) +
        extraPaddingToRemove) || 0
  );
};

/**
 * Retrieves the name of the renter.
 *
 * @return {string} The name of the renter, or an empty string if not found.
 */
const getCurrentApplicantNameFromApplication = (
  application: AllApplication,
  applicantId?: string
) => {
  const currentApplicant = application?.applicant_information?.applicants.find(
    (applicant) => applicant?.renter?.id === applicantId
  );

  return currentApplicant
    ? currentApplicant?.renter?.general_information?.first_name
    : '';
};

/**
 * Check if the `navigator` object and the `userAgent` property are available
 *
 * @return {boolean} whether the user is on a mobile device
 */
const isMobile = () => {
  // Check if the `navigator` object and the `userAgent` property are available
  if (typeof navigator !== 'undefined' && navigator.userAgent) {
    // Regular expression to match common mobile device user agents
    const mobileDeviceRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    // Check if the user agent string matches the regex
    return mobileDeviceRegex.test(navigator.userAgent);
  }
  // Return false if `navigator` or `userAgent` is not available (e.g., SSR)
  return false;
};

/**
 * Remove all non-numeric characters from the phone number string and format it based on the desired format.
 *
 * @param {string} phoneNumberString - the input phone number string
 * @return {string} the formatted phone number string
 */
function formatPhoneNumber(phoneNumberString: string) {
  if (!phoneNumberString) return phoneNumberString;

  // Remove all non-numeric characters from the phone number string
  const cleaned = phoneNumberString.replace(/\D/g, '');

  // Format the cleaned phone number based on the desired format
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // If the phone number doesn't match the expected format, return the original string
  return phoneNumberString;
}

/**
 * Checks if a given date is in the past.
 *
 * @param {string} date - The date to check in ISO string format.
 * @return {boolean} Returns true if the date is in the past, false otherwise.
 */
const isPastDate = (date: string) => {
  if (!date) return false;

  const expiryDate = new Date(date);

  const [year, month, day, hours, minutes, seconds] = [
    expiryDate.getFullYear(),
    expiryDate.getMonth(),
    expiryDate.getDate(),
    expiryDate.getHours(),
    expiryDate.getMinutes(),
    expiryDate.getSeconds(),
  ];
  const dateToCompare = new Date(year, month, day, hours, minutes, seconds);

  if (isPast(dateToCompare)) {
    return true;
  }

  return false;
};

/**
 * A function that calculates the height of the page header.
 *
 * @return {number} The height of the page header.
 */
const getPageHeaderHeight = () => {
  if (typeof document !== 'undefined') {
    const header = document?.getElementById('zeme-header');

    if (header) {
      return header.offsetHeight;
    }
  }

  return DEFAULT_HEADER_HEIGHT;
};

/**
 * Returns the minimum and maximum credit scores from the given application.
 *
 * @param {AllApplication} application - The application object containing applicant information.
 * @return {{minScore: number, maxScore: number}} - An object with the minimum and maximum credit scores.
 */
const getMinMaxCreditScore = (application?: AllApplication) => {
  const creditScore: number[] = [];
  let hasPending = false;

  (application?.applicant_information?.applicants || []).forEach(
    (applicant) => {
      if (!applicant?.credit_bypassed) {
        const ficoScore =
          applicant?.renter?.renter_information?.financial_information
            ?.credit_information?.fico_score;

        !ficoScore ? (hasPending = true) : creditScore.push(Number(ficoScore));
      }
    }
  );

  if (creditScore.length === 0) {
    return {
      minScore: hasPending ? 'PENDING' : -1,
      maxScore: hasPending ? 'PENDING' : -1,
    };
  }

  return {
    minScore: Math.min(...creditScore) || 0,
    maxScore: Math.max(...creditScore) || 0,
  };
};

/**
 * Validates if the input date string matches the MM/DD/YYYY format.
 *
 * @param {string} date - The date string to be validated.
 * @return {boolean} Returns true if the date string is in the correct format, false otherwise.
 */
function isValidDate(date: string) {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  return regex.test(date);
}

/**
 * Removes all non-numeric characters from the input, inserts slashes at specific positions, and limits the input length to 10 characters.
 *
 * @param {string} input - The input string to be processed.
 * @return {string} The processed input string with non-numeric characters removed, slashes inserted, and length limited to 10 characters.
 */
function maskDateInput(input: string) {
  // Remove all non-numeric characters
  input = input.replace(/\D/g, '');

  // Insert slashes at the correct positions
  if (input.length > 2) {
    input = input.slice(0, 2) + '/' + input.slice(2);
  }
  if (input.length > 5) {
    input = input.slice(0, 5) + '/' + input.slice(5);
  }

  // Limit the input length to 10 characters
  if (input.length > 10) {
    input = input.slice(0, 10);
  }

  return input;
}

/**
 * Formats a number by returning an integer if the input number is an integer, otherwise returns the number with two decimal places.
 *
 * @param {number} number - The number to be formatted.
 * @return {number} The formatted number.
 */
function formatNumber(number: number) {
  return Number.isInteger(number) ? number : number.toFixed(2);
}

/**
 * Returns a valid name if the input name meets the specified criteria, otherwise returns an empty string.
 *
 * @param {string} name - The input name to be validated.
 * @return {string} The valid name if the input name is valid, otherwise an empty string.
 */
const getValidName = (name: string) => {
  return name &&
    name.length >= 2 &&
    name.length <= 50 &&
    NAME_PATTERN.test(name)
    ? name
    : '';
};

/**
 * Calculate page to offset
 * @param pageNumber Number
 * @param pageSize Number
 * @returns Number
 */
function pageToOffset(pageNumber: number, pageSize: number) {
  return (pageNumber - 1) * pageSize;
}

/**
 * Checks if the given state is New York
 *
 * @param {string} state - The state to check
 * @returns {boolean} true if the state is New York, false otherwise
 */
const findIsNyState = (state: string) => {
  return ['NY', 'ny', 'New York'].includes(state);
};

export {
  transformAllPublicPropertiesData,
  transformPropertyItem,
  transformCreatePropertyPayloadItem,
  transFromPropertyToAddPropertyConext,
  transformAllAgentPropertiesData,
  transformEditRenterProfile,
  transformEditAgentProfile,
  transformStateToPayloadProfile,
  transformStateToPayloadAgentProfile,
  formatMapComponentData,
  combineAddress,
  splitAddress,
  splitAddressWithString,
  formatCurrency,
  propertyURLFilterBuilder,
  propertyURLSearchBuilder,
  formatNumberWithCommas,
  convertQueryStringToObject,
  getUpFrontCostChartData,
  isValidTime,
  formatDate,
  transformRenterProfile,
  transformFileToBlobURL,
  formatDateRelative,
  transformRenterHistoryPayload,
  formatZemeId,
  formatTime,
  formatDateAndTime,
  getApplicationStateAsPerRole,
  getStateLabelForRenter,
  getStateLabelForAgent,
  getSortOptionLable,
  getApplicationSorting,
  getOrigin,
  getURLQueryString,
  getAMPMFromTime,
  getHHMMFromTime,
  mapBoundUrlBuilder,
  removeQueryParam,
  getNameInitials,
  sortUrlBuilder,
  getDialogHeight,
  getCurrentApplicantNameFromApplication,
  formatCurrencyTwoDigitWithRound,
  combineAddressForFinix,
  isMobile,
  formatPhoneNumber,
  transformStateToPayloadLandlordProfile,
  isPastDate,
  getPageHeaderHeight,
  getMinMaxCreditScore,
  translatePropertyAddPropertyAddress,
  isValidDate,
  maskDateInput,
  formatNumber,
  getValidName,
  pageToOffset,
  findIsNyState,
};
